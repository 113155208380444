export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyBcd5r0nFGj49QWIZIkOvoxNmJ8RfJNuVI",
    authDomain: "labrador-f8560.firebaseapp.com",
    databaseURL: "https://labrador-f8560.firebaseio.com",
    projectId: "labrador-f8560",
    storageBucket: "labrador-f8560.appspot.com",
    messagingSenderId: "628090102143"
  },
  websocketHost: 'wss://backend.text-order.com',
  orderApiBackend: "https://backend.text-order.com",
  accessToken: "da6ff0a478066427d559b7a3dbe1f26463ad283a",
  iTokenUrl: "https://fts-uat.cardconnect.com",
  // hostName: "https://order.text-order.com"
  hostName: "https://referstage.pinvrf.com",
  referralHost: ["https://referstage.pinvrf.com"],
  referralPinvrf: "https://referstage.pinvrf.com"
};



